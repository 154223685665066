import React, {useContext, useEffect, useState} from "react";
import clsx from "clsx";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {
  ClickAwayListener,
  Grid,
  Drawer,
  AppBar,
  Toolbar,
  CssBaseline,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemText,
  Tabs,
  Tab,
  withWidth,
  Hidden,
  Paper
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  ExitToApp,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from "@material-ui/icons";
import {AuthContext} from '../context/authContext';
import {OrgContext} from '../context/orgContext';
import {LocalizationContext} from '../context/localizationContext';
import moment from 'moment';
import {permLevel} from '../functional/etc';
import NotificationMenu from '../components/NotificationMenuList';
import colors from '../styles/Theme-settings';
import Responsive from "./responsive";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  active: {
    color: colors.primary,
  },
  grid: {
    flexDirection: "row",
    width: "100%",
    height: "100%"
  },
  root: {
    display: "flex"
  },
  nameHolder: {
    borderLeft: '1px solid black',
    marginLeft: 15,
    paddingLeft: 15,
    paddingRight: 15,
    minHeight: 41,
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  appBar: {
    // borderBottom: 'none',
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: drawerWidth
  },
  menuBar: {
    // flexGrow: 1
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
    minHeight: '90px !important',
    position: 'fixed',
    top: 0,
    background: colors.white,
    zIndex: 9999,
    width: '100%',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginRight: -drawerWidth,
    paddingTop: 108,
    width: "100%",
    height: "100%"
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: 0
  },
  logedUser: {
    color: 'black',
  },
  logedOrg: {
    color: 'black',
    marginRight: 20,
  },
  logo: {
    cursor: 'pointer',
    maxHeight: 50,
    width: 90
  },
  toolbarActions: {
    display: 'flex',
    alignItems: 'center'
  },
  drawerUserBox: {
    position: 'fixed',
    bottom: 0,
    backgroundColor: 'white',
    width: 239,
    // width: '100%'
  },
  drawerMenuBox: {
    // position:'fixed',
    // top:90,
    // bottom:135,
    paddingTop: 90,
    paddingBottom: 135
  },
  settingsBox: {
    width: "100%",
    display: 'flex',
    justifyContent: 'space-between'
  },
  settingsIcon: {
    cursor: 'pointer',
  },
  menuIcon: {
    height: 40,
    width: 40,
    cursor: "pointer"
  },
  mobileToolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 20px",
    backgroundColor: "#f5f5f5"
  },
  mobileMain: {
    display: "flex",
    alignItems: "flex-start",
    paddingTop: 100
  },
  mobileContainer: {
    height: "100%"
  },
  menuIconMobile: {
    height: 40,
    width: 40,
    cursor: "pointer"
  },
  mainMobile: {
    height: "100%",
    padding: "0 40px"
  },
  termsOfService: {
    display: 'flex',
    alignItems: 'end',
    width: '100%',
    textAlign: 'left',
    color: '#a1a1a1',
    background: '#f5f5f5',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif;',
    textDecoration: 'none',
    paddingBottom: '16px',
    paddingTop: '16px',
    fontSize: '0.8em'
  }
}));

const TermsOfService = () => {
    const {getTranslation} = useContext(LocalizationContext);
    const classes = useStyles();
    return (
        <div className={classes.termsOfService}>
            <a href="/files/prodia_tos.pdf" target="_blank" style={{ color: 'inherit', textDecoration: 'none' }}>
                {getTranslation('layout_auth_terms_of_service')}
            </a>
        </div>
    );
};

const DashboardDesktop = props => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [btnClick, setBtnClick] = React.useState(false);
  const {logout, user, perm} = useContext(AuthContext);
  const {selectedOrg, setSelectedOrg} = useContext(OrgContext);
  const {getTranslation} = useContext(LocalizationContext);
  const getPerm = () => {
    return permLevel(selectedOrg);
  }
  const handleDrawerOpen = () => {
    setOpen(true);
    setBtnClick(true);
  };
  const handleDrawerClose = () => setOpen(false);
  useEffect(() => {
    setTimeout(() => setBtnClick(false), 1);
  }, [open]);

  const getActiveMenu = () => {
    let path = props.match.url;
    if ((path.indexOf('analyses') > -1) || (path === "/" && selectedOrg.mro)) {
      return 1;
    } else if (path.indexOf('referral') > -1) {
      return 2;
    } else if (path.indexOf('educational') > -1) {
      return 3;
    } else if (path.indexOf('importmvz') > -1) {
      return 11;
    } else if (path.indexOf('pdf-uploader') > -1) {
      return 12;
    } else if (path.indexOf('invoices') > -1) {
      return 4;
    } else if (path.indexOf('organizations') > -1) {
      return 10;
    } else if (path.indexOf('organization/selector') > -1) {
      return 8;
    } else if (path.indexOf(`organization/settings`) > -1) {
      return 5;
    } else if (path.indexOf(`organization`) > -1) {
      return 10;
    } else if (path.indexOf('warranty') > -1) {
      return 6;
    } else if (path.indexOf('translations') > -1) {
      return 7;
    } else if (path === '/' || path.indexOf('dashboard') > -1) {
      return 9;
    } else if ((path.indexOf('workplace') > -1)) {
      return 13;
    } else if (path.indexOf('patients') > -1) {
      return 14;
    } else if(path.indexOf("logs-listing")) {
      return 15
    } else {
      return false;
    }
  }

  return (
    <>
      <CssBaseline/>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
        elevation={3}
      >
        <Toolbar style={{justifyContent: 'space-between'}}>
          <img alt="Logo" src="/images/logo.png" className={classes.logo} onClick={() => props.history.push('/')}/>
          {selectedOrg &&
          <>
            {!selectedOrg.mro ?
              (
                <Hidden mdDown>
                  <Tabs className={classes.menuBar} value={false}>
                    {(selectedOrg && Number(selectedOrg.patientmodule) === 1 || Number(selectedOrg.id) === 1) && !selectedOrg.consult &&
                    <Tab
                      disableRipple
                      className={getActiveMenu() === 14 ? classes.active : ''}
                      label={getTranslation('layout_menu_patients')}
                      index={0}
                      onClick={() => props.history.push("/patients")}
                    />
                    }
                    { !selectedOrg.consult &&
                        <Tab
                        disableRipple
                        className={getActiveMenu() === 1 ? classes.active : ''}
                        label={getTranslation('layout_menu_analysis')}
                        index={0}
                        onClick={() => props.history.push("/analyses")}
                        />
                    }
                    {getPerm() < 3 &&
                    <Tab
                      disableRipple
                      className={getActiveMenu() === 2 ? classes.active : ''}
                      label={getTranslation('layout_menu_referal')}
                      index={1}
                      onClick={() => props.history.push("/referral")}
                    />
                    }
                    {((selectedOrg.workplace === "1" && getPerm() <= 2) || getPerm() >= 3) &&
                    <Tab
                      disableRipple
                      className={getActiveMenu() === 13 ? classes.active : ''}
                      label={getTranslation('layout_menu_workplace')}
                      index={1}
                      onClick={() => props.history.push("/workplace")}
                    />
                    }
                    <Tab
                      disableRipple
                      className={getActiveMenu() === 3 ? classes.active : ''}
                      label={getTranslation('layout_menu_educational')}
                      index={2}
                      onClick={() => props.history.push("/educational")}
                    />
                    { !selectedOrg.consult &&
                        <Tab
                        disableRipple
                        className={getActiveMenu() === 3 ? classes.active : ''}
                        label={getTranslation('layout_menu_webshop')}
                        index={2}
                        onClick={() => window.open('https://www.prodia.se/drogtester/', '_blank')}
                        />
                    }
                    {selectedOrg && selectedOrg.id === "1" && !selectedOrg.consult &&
                    < Tab
                      disableRipple
                      className={getActiveMenu() === 4 ? classes.active : ''}
                      label={getTranslation('layout_menu_invoices')}
                      index={3}
                      onClick={() => props.history.push("/invoices")}
                    />
                    }
                  </Tabs>
                </Hidden>
              )
              :
              /*MRO*/
              (
                <Tabs className={classes.menuBar} value={false}>
                  <Tab
                    disableRipple
                    className={getActiveMenu() === 1 ? classes.active : ''}
                    label={getTranslation('layout_menu_analysis')}
                    index={0}
                    onClick={() => props.history.push("/analyses")}
                  />
                  <Tab
                    disableRipple
                    className={getActiveMenu() === 5 ? classes.active : ''}
                    label={getTranslation('layout_menu_my_page')}
                    index={0}
                    onClick={() => props.history.push("/organization/settings")}
                  />
                </Tabs>
              )
            }
          </>
          }
          <div className={classes.toolbarActions}>
            <NotificationMenu/>
            <Hidden mdDown>
              <div className={classes.nameHolder}>
                <Typography variant="body2" className={classes.logedUser}>
                  {user && user.name}
                </Typography>
                {(selectedOrg && selectedOrg.id != 1 && selectedOrg.custNr) &&
                <Typography variant="caption" className={classes.logedOrg}>
                  {getTranslation('layout_menu_selected_org').replace('##number##', selectedOrg.custNr)}
                </Typography>
                }
              </div>
            </Hidden>
            {!selectedOrg &&
            <IconButton
              color="inherit"
              onClick={() => {
                logout().then(() =>
                  props.history.push("/login")
                )
              }}
            >
              <ExitToApp/>
            </IconButton>
            }
            {selectedOrg &&
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerOpen}
              className={clsx(open && classes.hide)}
            >
              <MenuIcon style={{width: 23, height: 23}}/>
            </IconButton>
            }
          </div>
        </Toolbar>
        {selectedOrg && <ClickAwayListener onClickAway={(e) => {
          if (open && !btnClick) {
            handleDrawerClose()
          }
        }}>
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="right"
            open={open}
            classes={{
              paper: classes.drawerPaper
            }}
          >
            <div className={classes.drawerHeader} elevation={3}>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronLeftIcon style={{width: 23, height: 23}}/>
                ) : (
                  <ChevronRightIcon style={{width: 23, height: 23}}/>
                )}
              </IconButton>
            </div>
            {!selectedOrg.mro ?
              (<div>
                <div className={classes.drawerMenuBox}>
                  <Hidden lgUp>
                    {/* <Divider /> */}
                    {(selectedOrg && Number(selectedOrg.patientmodule) === 1 || Number(selectedOrg.id) === 1) && !selectedOrg.consult &&
                    <ListItem button>
                      <ListItemText
                        className={getActiveMenu() === 14 ? classes.active : ''}
                        primary={getTranslation('layout_menu_patients')}
                        onClick={() => {
                          setOpen(false);
                          props.history.push("/patients")
                        }}
                      />
                    </ListItem>
                    }
                    { !selectedOrg.consult &&
                        <ListItem button>
                            <ListItemText
                                className={getActiveMenu() === 1 ? classes.active : ''}
                                primary={getTranslation('layout_menu_analysis')}
                                onClick={() => {
                                setOpen(false);
                                props.history.push("/analyses")
                                }}
                            />
                        </ListItem>
                    }
                    {getPerm() < 3 && !selectedOrg.consult &&
                    <ListItem button>
                      <ListItemText
                        className={getActiveMenu() === 2 ? classes.active : ''}
                        primary={getTranslation('layout_menu_referal')}
                        onClick={() => {
                          setOpen(false);
                          props.history.push("/referral")
                        }}
                      />
                    </ListItem>
                    }
                    {((selectedOrg.workplace === "1" && getPerm() < 2) || getPerm() >= 3) &&
                    <ListItem button>
                      <ListItemText
                        className={getActiveMenu() === 13 ? classes.active : ''}
                        primary={getTranslation('layout_menu_workplace')}
                        onClick={() => {
                          setOpen(false);
                          props.history.push("/workplace")
                        }}
                      />
                    </ListItem>
                    }
                    <ListItem button>
                      <ListItemText
                        className={getActiveMenu() === 3 ? classes.active : ''}
                        primary={getTranslation('layout_menu_educational')}
                        onClick={() => {
                          setOpen(false);
                          props.history.push("/educational")
                        }}
                      />
                    </ListItem>
                    { !selectedOrg.consult &&
                        <ListItem button>
                            <ListItemText
                                className={getActiveMenu() === 10 ? classes.active : ''}
                                primary={getTranslation('layout_menu_webshop')}
                                onClick={() => {
                                setOpen(false);
                                window.open('https://www.prodiagnostics.se/drogtester/', '_blank');
                                }}
                            />
                        </ListItem>
                    }
                    {selectedOrg && selectedOrg.id === "1" && !selectedOrg.consult &&
                    <ListItem button>
                      <ListItemText
                        className={getActiveMenu() === 4 ? classes.active : ''}
                        primary={getTranslation('layout_menu_invoices')}
                        onClick={() => {
                          setOpen(false);
                          props.history.push("/invoices")
                        }}
                      />
                    </ListItem>
                    }
                    <Divider/>
                  </Hidden>

                  <List>
                    <ListItem button>
                      <ListItemText
                        className={getActiveMenu() === 9 ? classes.active : ''}
                        primary={getTranslation('layout_menu_home')}
                        onClick={() => {
                          setOpen(false);
                          props.history.push("/dashboard")
                        }}
                      />
                    </ListItem>
                    {getPerm() > 2 && !selectedOrg.consult &&
                    <ListItem button>
                      <ListItemText
                        className={getActiveMenu() === 11 ? classes.active : ''}
                        primary={getTranslation('layout_menu_mvz')}
                        onClick={() => {
                          setOpen(false);
                          props.history.push("/invoices/importmvz")
                        }}
                      />
                    </ListItem>
                    }
                    {getPerm() > 2 && !selectedOrg.consult &&
                    <ListItem button>
                      <ListItemText
                        className={getActiveMenu() === 12 ? classes.active : ''}
                        primary={getTranslation('layout_menu_pdf_uploader')}
                        onClick={() => {
                          setOpen(false);
                          props.history.push("/invoices/pdf-uploader")
                        }}
                      />
                    </ListItem>
                    }
                    {getPerm() > 2 && !selectedOrg.consult &&
                    <ListItem button>
                      <ListItemText
                        className={getActiveMenu() === 10 ? classes.active : ''}
                        primary={getTranslation('layout_menu_organizations')}
                        onClick={() => {
                          setOpen(false);
                          props.history.push("/organizations")
                        }}
                      />
                    </ListItem>
                    }
                    {(selectedOrg && selectedOrg.id) &&
                    <ListItem button>
                      <ListItemText
                        className={getActiveMenu() === 5 ? classes.active : ''}
                        primary={getTranslation('layout_menu_my_page')}
                        onClick={() => {
                          setOpen(false);
                          props.history.push(`/organization/settings`)
                        }}
                      />
                    </ListItem>
                    }
                    {getPerm() < 3 && !selectedOrg.consult &&
                    <ListItem button>
                      <ListItemText
                        className={getActiveMenu() === 6 ? classes.active : ''}
                        primary={getTranslation('layout_menu_warranty_request')}
                        onClick={() => {
                          setOpen(false);
                          props.history.push("/warranty")
                        }}
                      />
                    </ListItem>
                    }
                    {getPerm() > 2 && !selectedOrg.consult &&
                    <ListItem button>
                      <ListItemText
                        className={getActiveMenu() === 7 ? classes.active : ''}
                        primary={getTranslation('layout_menu_translations')}
                        onClick={() => {
                          setOpen(false);
                          props.history.push("/translations")
                        }}
                      />
                    </ListItem>
                    }
                    {getPerm() > 2 && !selectedOrg.consult &&
                    <ListItem button>
                      <ListItemText
                        className={getActiveMenu() === 15 ? classes.active : ''}
                        primary={getTranslation('layout_menu_logs_listing')}
                        onClick={() => {
                          setOpen(false);
                          props.history.push("/logs-listing")
                        }}
                      />
                    </ListItem>
                    }
                  </List>
                  <Divider/>
                  {perm && perm.options.length > 1 &&
                  <>
                    <ListItem button>
                      <ListItemText
                        className={getActiveMenu() === 8 ? classes.active : ''}
                        primary={getTranslation('layout_menu_organization_selector')}
                        onClick={() => {
                          setOpen(false);
                          props.history.push(`/organization/selector`)
                        }}
                      />
                    </ListItem>
                    <Divider/>
                  </>
                  }
                  <List>
                    <ListItem button>
                      <ListItemText
                        primary={getTranslation('layout_menu_logout')}
                        onClick={() => {
                          setOpen(false);
                          logout().then(() => {
                            setSelectedOrg(null);
                            props.history.push("/login");
                          })

                        }}
                      />
                    </ListItem>
                  </List>
                </div>
                <div className={classes.drawerUserBox}>
                  <List>
                    <ListItem>
                      <Typography variant="caption" display="block" gutterBottom>
                        <b>{getTranslation('layout_menu_user_signed')}</b>{' '}{user && user.name}
                      </Typography>
                    </ListItem>
                    {(selectedOrg && selectedOrg.id != 1 && selectedOrg.custNr) &&
                    <ListItem>
                      <Typography variant="caption" display="block" gutterBottom>
                        <b>{getTranslation('layout_menu_selected_org').replace('##number##', '')}</b>{' '}{selectedOrg.custNr}
                      </Typography>
                    </ListItem>
                    }
                    <ListItem>
                      <Typography variant="caption" display="block" gutterBottom>
                        <b>{getTranslation('layout_menu_user_last_login')}</b>{' '}{user && moment(user.lastLogin).format('DD/MM/YYYY HH:mm')}
                      </Typography>
                    </ListItem>
                  </List>
                </div>
              </div>)
              :
              (<div>
                <div className={classes.drawerMenuBox}>
                  <List>
                    <ListItem button>
                      <ListItemText
                        className={getActiveMenu() === 1 ? classes.active : ''}
                        primary={getTranslation('layout_menu_analysis')}
                        onClick={() => {
                          setOpen(false);
                          props.history.push("/analyses")
                        }}
                      />
                    </ListItem>
                    <ListItem button>
                      <ListItemText
                        className={getActiveMenu() === 5 ? classes.active : ''}
                        primary={getTranslation('layout_menu_my_page')}
                        onClick={() => {
                          setOpen(false);
                          props.history.push(`/organization/settings`)
                        }}
                      />
                    </ListItem>
                    <Divider/>
                    <ListItem button>
                      <ListItemText
                        primary={getTranslation('layout_menu_logout')}
                        onClick={() => {
                          setOpen(false);
                          logout().then(() => {
                            setSelectedOrg(null);
                            props.history.push("/login");
                          })
                        }}
                      />
                    </ListItem>
                  </List>
                </div>
                <div className={classes.drawerUserBox}>
                  <List>
                    <ListItem>
                      <Typography variant="caption" display="block" gutterBottom>
                        <b>{getTranslation('layout_menu_user_signed')}</b>{' '}{user && user.name}
                      </Typography>
                    </ListItem>
                    {(selectedOrg && selectedOrg.id != 1 && selectedOrg.custNr) &&
                    <ListItem>
                      <Typography variant="caption" display="block" gutterBottom>
                        <b>{getTranslation('layout_menu_selected_org').replace('##number##', '')}</b>{' '}{selectedOrg.custNr}
                      </Typography>
                    </ListItem>
                    }
                    <ListItem>
                      <Typography variant="caption" display="block" gutterBottom>
                        <b>{getTranslation('layout_menu_user_last_login')}</b>{' '}{user && moment(user.lastLogin).format('DD/MM/YYYY HH:mm')}
                      </Typography>
                    </ListItem>
                  </List>
                </div>
              </div>)
            }
          </Drawer>
        </ClickAwayListener>}
      </AppBar>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open
        })}
      >
        <Grid container className={classes.grid}>
          {props.children}
          <TermsOfService />
        </Grid>
      </main>
    </>
  );
};

const DashboardMobile = props => {
  const classes = useStyles();
  const theme = useTheme();
  const {selectedOrg, setSelectedOrg} = useContext(OrgContext);
  const {getTranslation} = useContext(LocalizationContext);
  const {logout, user, perm} = useContext(AuthContext);

  const [open, setOpen] = useState(false);

  const handleDrawerClose = () => setOpen(false);

  const getPerm = () => {
    return permLevel(selectedOrg);
  }

  const getActiveMenu = () => {
    let path = props.match.url;
    if (path.indexOf('analyses') > -1) {
      return 1;
    } else if (path.indexOf('referral') > -1) {
      return 2;
    } else if (path.indexOf('educational') > -1) {
      return 3;
    } else if (path.indexOf('importmvz') > -1) {
      return 11;
    } else if (path.indexOf('pdf-uploader') > -1) {
      return 12;
    } else if (path.indexOf('invoices') > -1) {
      return 4;
    } else if (path.indexOf('organizations') > -1) {
      return 10;
    } else if (path.indexOf('organization/selector') > -1) {
      return 8;
    } else if (path.indexOf(`organization/settings`) > -1) {
      return 5;
    } else if (path.indexOf(`organization`) > -1) {
      return 10;
    } else if (path.indexOf('warranty') > -1) {
      return 6;
    } else if (path.indexOf('translations') > -1) {
      return 7;
    } else if (path === '/' || path.indexOf('dashboard') > -1) {
      return 9;
    } else if ((path.indexOf('workplace') > -1)) {
      return 13;
    } else if (path.indexOf('patients') > -1) {
      return 14;
    } else {
      return false;
    }
  }

  return (
    <>
      <AppBar
        position="fixed"
        className={classes.appBar}
        elevation={3}
      >
        <Toolbar style={{justifyContent: 'space-between'}}>
          <img alt="Logo" src="/images/logo.png" className={classes.logo} onClick={() => props.history.push('/')}/>
          <div className={classes.toolbarActions}>
            {selectedOrg &&
            <MenuIcon className={classes.menuIconMobile} onClick={() => setOpen(true)}/>
            }
            {!selectedOrg &&
            <div className={classes.toolbarActions}>
              <NotificationMenu/>
              <Hidden mdDown>
                <div className={classes.nameHolder}>
                  <Typography variant="body2" className={classes.logedUser}>
                    {user && user.name}
                  </Typography>
                  {(selectedOrg && selectedOrg.id != 1 && selectedOrg.custNr) &&
                  <Typography variant="caption" className={classes.logedOrg}>
                    {getTranslation('layout_menu_selected_org').replace('##number##', selectedOrg.custNr)}
                  </Typography>
                  }
                </div>
              </Hidden>
              <IconButton
                color="inherit"
                onClick={() => {
                  logout().then(() =>
                    props.history.push("/login")
                  )
                }}
              >
                <ExitToApp/>
              </IconButton>
            </div>}
          </div>
        </Toolbar>
        {selectedOrg &&
        <Drawer
          className={classes.drawer}
          anchor="right"
          open={open}
          onClose={() => setOpen(false)}
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <div className={classes.drawerHeader} elevation={3}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronLeftIcon style={{width: 23, height: 23}}/>
              ) : (
                <ChevronRightIcon style={{width: 23, height: 23}}/>
              )}
            </IconButton>
          </div>
          {!selectedOrg.mro ?
            (<div>
              <div className={classes.drawerMenuBox}>
                <Hidden lgUp>
                  {/* <Divider /> */}
                  {selectedOrg &&
                  <>
                    {(Number(selectedOrg.patientmodule) === 1 || Number(selectedOrg.id) === 1) && !selectedOrg.consult &&
                    <ListItem button>
                      <ListItemText
                        className={getActiveMenu() === 14 ? classes.active : ''}
                        primary={getTranslation('layout_menu_patients')}
                        onClick={() => {
                          setOpen(false);
                          props.history.push("/patients")
                        }}
                      />
                    </ListItem>
                    }
                  </>
                  }
                  { !selectedOrg.consult &&
                  <ListItem button>
                    <ListItemText
                      className={getActiveMenu() === 1 ? classes.active : ''}
                      primary={getTranslation('layout_menu_analysis')}
                      onClick={() => {
                        setOpen(false);
                        props.history.push("/analyses")
                      }}
                    />
                  </ListItem>
                  }
                  {getPerm() < 3 && !selectedOrg.consult &&
                  <ListItem button>
                    <ListItemText
                      className={getActiveMenu() === 2 ? classes.active : ''}
                      primary={getTranslation('layout_menu_referal')}
                      onClick={() => {
                        setOpen(false);
                        props.history.push("/referral")
                      }}
                    />
                  </ListItem>
                  }
                  {((selectedOrg.workplace === "1" && getPerm() < 2) || getPerm() >= 3) &&
                  <ListItem button>
                    <ListItemText
                      className={getActiveMenu() === 13 ? classes.active : ''}
                      primary={getTranslation('layout_menu_workplace')}
                      onClick={() => {
                        setOpen(false);
                        props.history.push("/workplace")
                      }}
                    />
                  </ListItem>
                  }
                  <ListItem button>
                    <ListItemText
                      className={getActiveMenu() === 3 ? classes.active : ''}
                      primary={getTranslation('layout_menu_educational')}
                      onClick={() => {
                        setOpen(false);
                        props.history.push("/educational")
                      }}
                    />
                  </ListItem>
                  { !selectedOrg.consult &&
                  <ListItem button>
                    <ListItemText
                      className={getActiveMenu() === 10 ? classes.active : ''}
                      primary={getTranslation('layout_menu_webshop')}
                      onClick={() => {
                        setOpen(false);
                        window.open('https://www.prodia.se/drogtester/', '_blank');
                      }}
                    />
                  </ListItem>
                  }
                  {selectedOrg && selectedOrg.id === "1" && !selectedOrg.consult &&
                  <ListItem button>
                    <ListItemText
                      className={getActiveMenu() === 4 ? classes.active : ''}
                      primary={getTranslation('layout_menu_invoices')}
                      onClick={() => {
                        setOpen(false);
                        props.history.push("/invoices")
                      }}
                    />
                  </ListItem>
                  }
                  <Divider/>
                </Hidden>

                <List>
                  <ListItem button>
                    <ListItemText
                      className={getActiveMenu() === 9 ? classes.active : ''}
                      primary={getTranslation('layout_menu_home')}
                      onClick={() => {
                        setOpen(false);
                        props.history.push("/dashboard")
                      }}
                    />
                  </ListItem>
                  {getPerm() > 2 && !selectedOrg.consult &&
                  <ListItem button>
                    <ListItemText
                      className={getActiveMenu() === 11 ? classes.active : ''}
                      primary={getTranslation('layout_menu_mvz')}
                      onClick={() => {
                        setOpen(false);
                        props.history.push("/invoices/importmvz")
                      }}
                    />
                  </ListItem>
                  }
                  {getPerm() > 2 && !selectedOrg.consult &&
                  <ListItem button>
                    <ListItemText
                      className={getActiveMenu() === 12 ? classes.active : ''}
                      primary={getTranslation('layout_menu_pdf_uploader')}
                      onClick={() => {
                        setOpen(false);
                        props.history.push("/invoices/pdf-uploader")
                      }}
                    />
                  </ListItem>
                  }
                  {getPerm() > 2 && !selectedOrg.consult &&
                  <ListItem button>
                    <ListItemText
                      className={getActiveMenu() === 10 ? classes.active : ''}
                      primary={getTranslation('layout_menu_organizations')}
                      onClick={() => {
                        setOpen(false);
                        props.history.push("/organizations")
                      }}
                    />
                  </ListItem>
                  }
                  {(selectedOrg && selectedOrg.id) &&
                  <ListItem button>
                    <ListItemText
                      className={getActiveMenu() === 5 ? classes.active : ''}
                      primary={getTranslation('layout_menu_my_page')}
                      onClick={() => {
                        setOpen(false);
                        props.history.push(`/organization/settings`)
                      }}
                    />
                  </ListItem>
                  }
                  {getPerm() < 3 && !selectedOrg.consult &&
                  <ListItem button>
                    <ListItemText
                      className={getActiveMenu() === 6 ? classes.active : ''}
                      primary={getTranslation('layout_menu_warranty_request')}
                      onClick={() => {
                        setOpen(false);
                        props.history.push("/warranty")
                      }}
                    />
                  </ListItem>
                  }
                  {getPerm() > 2 && !selectedOrg.consult &&
                  <ListItem button>
                    <ListItemText
                      className={getActiveMenu() === 7 ? classes.active : ''}
                      primary={getTranslation('layout_menu_translations')}
                      onClick={() => {
                        setOpen(false);
                        props.history.push("/translations")
                      }}
                    />
                  </ListItem>
                  }
                  {getPerm() > 2 && !selectedOrg.consult &&
                    <ListItem button>
                      <ListItemText
                        className={getActiveMenu() === 15 ? classes.active : ''}
                        primary={getTranslation('layout_menu_logs_listing')}
                        onClick={() => {
                          setOpen(false);
                          props.history.push("/logs-listing")
                        }}
                      />
                    </ListItem>
                    }
                </List>
                <Divider/>
                {perm && perm.options.length > 1 &&
                <>
                  <ListItem button>
                    <ListItemText
                      className={getActiveMenu() === 8 ? classes.active : ''}
                      primary={getTranslation('layout_menu_organization_selector')}
                      onClick={() => {
                        setOpen(false);
                        props.history.push(`/organization/selector`)
                      }}
                    />
                  </ListItem>
                  <Divider/>
                </>
                }
                <List>
                  <ListItem button>
                    <ListItemText
                      primary={getTranslation('layout_menu_logout')}
                      onClick={() => {
                        setOpen(false);
                        logout().then(() => {
                          setSelectedOrg(null);
                          props.history.push("/login");
                        })

                      }}
                    />
                  </ListItem>
                </List>
              </div>
              <div className={classes.drawerUserBox}>
                <List>
                  <ListItem>
                    <Typography variant="caption" display="block" gutterBottom>
                      <b>{getTranslation('layout_menu_user_signed')}</b>{' '}{user && user.name}
                    </Typography>
                  </ListItem>
                  {(selectedOrg && selectedOrg.id != 1 && selectedOrg.custNr) &&
                  <ListItem>
                    <Typography variant="caption" display="block" gutterBottom>
                      <b>{getTranslation('layout_menu_selected_org').replace('##number##', '')}</b>{' '}{selectedOrg.custNr}
                    </Typography>
                  </ListItem>
                  }
                  <ListItem>
                    <Typography variant="caption" display="block" gutterBottom>
                      <b>{getTranslation('layout_menu_user_last_login')}</b>{' '}{user && moment(user.lastLogin).format('DD/MM/YYYY HH:mm')}
                    </Typography>
                  </ListItem>
                </List>
              </div>
            </div>)
            :
            (<div>
              <div className={classes.drawerMenuBox}>
                <List>
                  <ListItem button>
                    <ListItemText
                      className={getActiveMenu() === 1 ? classes.active : ''}
                      primary={getTranslation('layout_menu_analysis')}
                      onClick={() => {
                        setOpen(false);
                        props.history.push(`/analyses`)
                      }}
                    />
                  </ListItem>
                  <ListItem button>
                    <ListItemText
                      className={getActiveMenu() === 5 ? classes.active : ''}
                      primary={getTranslation('layout_menu_my_page')}
                      onClick={() => {
                        setOpen(false);
                        props.history.push(`/organization/settings`)
                      }}
                    />
                  </ListItem>
                  <Divider/>
                  <ListItem button>
                    <ListItemText
                      primary={getTranslation('layout_menu_logout')}
                      onClick={() => {
                        setOpen(false);
                        logout().then(() => {
                          setSelectedOrg(null);
                          props.history.push("/login");
                        })

                      }}
                    />
                  </ListItem>
                </List>
              </div>
              <div className={classes.drawerUserBox}>
                <List>
                  <ListItem>
                    <Typography variant="caption" display="block" gutterBottom>
                      <b>{getTranslation('layout_menu_user_signed')}</b>{' '}{user && user.name}
                    </Typography>
                  </ListItem>
                  {(selectedOrg && selectedOrg.id != 1 && selectedOrg.custNr) &&
                  <ListItem>
                    <Typography variant="caption" display="block" gutterBottom>
                      <b>{getTranslation('layout_menu_selected_org').replace('##number##', '')}</b>{' '}{selectedOrg.custNr}
                    </Typography>
                  </ListItem>
                  }
                  <ListItem>
                    <Typography variant="caption" display="block" gutterBottom>
                      <b>{getTranslation('layout_menu_user_last_login')}</b>{' '}{user && moment(user.lastLogin).format('DD/MM/YYYY HH:mm')}
                    </Typography>
                  </ListItem>
                </List>
              </div>
            </div>)
          }
        </Drawer>}
      </AppBar>
      <main className={classes.mainMobile}>
        <Grid container className={classes.grid} style={{paddingTop: 120}}>
          {props.children}
          <TermsOfService />
        </Grid>
      </main>
    </>
  );
}

const DashboardUI = props => {
  return <Responsive desktop={DashboardDesktop} tablet={DashboardMobile} mobile={DashboardMobile}{...props}/>
};

export default withWidth()(DashboardUI);
