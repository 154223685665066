import React, {useState, useContext, useEffect, Fragment} from "react";
import {
    Grid,
    Paper,
    Button,
    Typography,
    TextField,
    Collapse,
    Hidden,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Checkbox,
    FormControlLabel, FormControl, RadioGroup, Radio,
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import {makeStyles} from "@material-ui/core/styles";
import SimpleTable from "../../components/analysis/AnalysesDetailTable";
import {LocalizationContext} from "../../context/localizationContext";
import {OrgContext} from "../../context/orgContext";
import {AuthContext} from "../../context/authContext";
import APICall from "../../functional/APIRequests";
import {useSnackbar} from "notistack";
import moment from "moment";
import {Autocomplete} from "@material-ui/lab";

import {
    Check,
    PictureAsPdf,
    Assignment,
    ArrowBackIos,
    Launch,
} from "@material-ui/icons";
import {apiServer} from "../../functional/generateEnvUrls";
import {permLevel} from "../../functional/etc";
import clsx from "clsx";
import colorConfig from "../../styles/Theme-settings";

const useStyles = makeStyles((theme) => ({
    container: {
        flexDirection: "row",
        justifyContent: "center",
        display: "flex",
    },
    paper: {
        padding: 20,
        width: "100%",
    },
    paperTitle: {
        width: "100%",
        padding: 20,
        marginBottom: 20,
    },
    cartButton: {
        width: "100%",
        marginBottom: 10,
    },
    expand: {
        marginBottom: -3,
    },
    pointer: {
        cursor: "pointer",
        paddingTop: 3,
    },
    patientInfoWrap: {
        marginTop: 15,
    },
    archiveBtn: {
        margin: "0px 10px",
    },
    header: {
        backgroundColor: '#E41937',
        padding: 8,
        width: "100%",
        color: 'white',
        [theme.breakpoints.down(1024)]: {
            width: '103%',
        },
    },
    forControlLabelCheckBoxApprove: {
        cursor: "pointer",
        '&.Mui-disabled': {
            cursor: 'pointer',
        }
    },
    checkboxMro: {
        color: 'white',
        '&.Mui-checked': {
            color: 'white',
        },
        '& .MuiSvgIcon-root': {
            fill: 'white',
        }
    },
    mroTextInput: {
        padding: "10px 0px",
        "& .MuiFormHelperText-root": {
            color: "#ff1744"
        }
    },
    mroApproveRadioForm: {
        padding: "0px 10px",
        paddingLeft: 0
    },
    mroApprovalFormControl: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0
    },
    mroUploadFileInput: {
        display: "none"
    },
    mroRadioHelperText: {
        color: "#ff1744",
        marginLeft: 14,
        fontSize: 12,
        lineHeight: "19.9167px"
    },
    headerMro: {
        backgroundColor: "#9d9d9d",
        padding: 8,
        width: "100%",
        color: 'white',
        [theme.breakpoints.down(1024)]: {
            width: '103%',
        },
    }
}));
const SendDigitalInfo = (props) => {
    const {getTranslation} = useContext(LocalizationContext);

    const {sendDigital, mvzExportDate} = props;

    if (!sendDigital || sendDigital === 0 || sendDigital === "0") {
        return (
            <Typography variant="body2" gutterBottom style={{color: "#B7B7B7"}}>
                <Check style={{fill: "#B7B7B7"}}/>
                {getTranslation("analysis_detail_page_sent_digital_no_date")}
            </Typography>
        );
    } else {
        if (!mvzExportDate || mvzExportDate === 0 || mvzExportDate === "0") {
            return (
                <Typography variant="body2" gutterBottom style={{color: "#28A745"}}>
                    <Check style={{fill: "#28A745"}}/>
                    {getTranslation("analysis_detail_page_sent_digital_no_date")}
                </Typography>
            );
        } else {
            return (
                <Typography variant="body2" gutterBottom style={{color: "#28A745"}}>
                    <Check style={{fill: "#28A745"}}/>
                    {getTranslation(
                        "analysis_detail_page_sent_digital_with_date"
                    ).replace(
                        "##date##",
                        moment.unix(mvzExportDate).format("YYYY-MM-DD")
                    )}
                </Typography>
            );
        }
    }
};

const AnalysesDetail = (props) => {
    const classes = useStyles();
    const {getTranslation} = useContext(LocalizationContext);
    const {enqueueSnackbar} = useSnackbar();
    const {selectedOrg} = useContext(OrgContext);
    const {setLoading, user} = useContext(AuthContext);
    const [data, setData] = useState({});
    const [status, setStatus] = useState([]);
    let referalId = props.match.params.id.toLowerCase();
    const [clients, setClients] = useState();
    const [customerId, setCustomerId] = useState();
    const [testCaseTesters, setTestCaseTesters] = useState([]);
    const [patient, setPatient] = useState({name: "", id: "", pnr: ""});
    const [analysisDetData, setAnalysisDetData] = useState({
        organisation_id: "",
        organisation_name: "",
        test_date: "",
        registration_date: "",
        arrival_date: "",
        result_date: "",
        notified_users: [],
        patient_data: null,
    });
    const [open, setOpen] = useState(false);
    const [testCaseData, setTestCaseData] = useState({});
    const [mroApproved, setMroApproved] = useState(false);
    const [isWorkplace, setIsWorkplace] = useState(false);
    const [isExpressReferral, setIsExpressReferral] = useState(false);
    const [expressReferralHasData, setExpressReferralHasData] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [mroUser, setMroUser] = useState({});
    const [mroText, setMroText] = useState("");
    const [mroDialogOpen, setMroDialogOpen] = useState(false);
    const [mroApprovalValue, setMroApprovalValue] = useState("");
    const [mroFile, setMroFile] = useState(null);
    const [resultsData, setResultsData] = useState([]);
    const [count, setCount] = useState(0);
    const [approveChecked, setApproveChecked] = useState(false);
    const sort = {column: 'sys_id', by: 'asc'};

    document.title =
        getTranslation("analysis_detail_browser_title").replace(
            "##id##",
            analysisDetData.referral_id ? ` #${analysisDetData.referral_id}` : ""
        ) + " | Prodiagnostics";
    const fetchData = () => {
        setLoading(true);
        // Express Referral endpoint call
        APICall({
            setLoading,
            hasRefresh: true,
            enqueueSnackbar,
            url: `customer/${selectedOrg.id}/express_referral/${referalId}`,
            verb: "get",
            server: "api",
        }).then((res) => {
            setLoading(false);
            // Check if express referral else - fetch regular referral
            if (res.data?.expressreferral?.sys_id) {
                setIsWorkplace(true);
                setIsExpressReferral(true);
                if (Object.keys(res.data.referral).length > 0) {
                    setExpressReferralHasData(true);
                }
                if (res) setData(res.data);
                let data = res.data;
                if (data) {
                    setAnalysisDetData({
                        ...analysisDetData,
                        sys_id: data.organisation.sys_id,
                        referral_id: data.expressreferral.express_referral_id,
                        organisation_id: data.organisation
                            ? data.organisation.customer_number
                            : "",
                        organisation_db_id: data.organisation ? data.organisation.sys_id : "",
                        organisation_name: data.organisation ? data.organisation.name : "",
                        test_case_id: data.expressreferral.test_case_id,
                        archived_by: data.expressreferral.archived_by,
                        archived_at: data.expressreferral.archived_at,
                        handled_by: data.expressreferral.handled_by,
                        handled_at: data.expressreferral.handled_at,
                        file_id: data.expressreferral.file_id,
                        deleted: data.expressreferral.deleted,
                        deleted_by: data.expressreferral.deleted_by,
                        status_id: data.expressreferral.status_id,
                        user_id: data.expressreferral.user_id,
                        user_handled: data.user_handled.name,
                        user_archived: data.user_archived.name,
                        registration_date: moment.unix(data.referral.registration_date).format("YYYY-MM-DD HH:mm"),
                        arrival_date: (data.referral && data.referral.arrival_date && data.referral.arrival_date !== "0") ? moment.unix(data.referral.arrival_date).format("YYYY-MM-DD HH:mm") : null,
                        result_date: (data.referral && data.referral.result_date && data.referral.result_date !== "0") ? moment.unix(data.referral.result_date).format("YYYY-MM-DD HH:mm") : null,
                        referral_sys_id: (data.referral && data.referral.sys_id) ? data.referral.sys_id : data.expressreferral.sys_id,
                        is_positive: (data.referral && data.referral.is_positive) ? (data.referral.is_positive === "1" ? true : false) : false,
                        created_date: (data.expressreferral && data.expressreferral.created_date ?  moment.unix(data.expressreferral.created_date).format("YYYY-MM-DD HH:mm") : null)
                    })
                    if (data.organisation && data.organisation.sys_id === '2') {
                        fetchClients();
                    }

                    if (data.expressreferral.test_case_id) {
                        fetchTestCase(data.expressreferral.test_case_id)
                        fetchTestcaseTesters(data.expressreferral.test_case_id);
                    }

                    /*  if (res.data.referral.patient_name) {
                        setPatient({
                          ...patient,
                          ["name"]: res.data.referral.patient_name,
                          ["id"]: res.data.referral.patient_id,
                          ["pnr"]: res.data.referral.patient_pnr
                        });
                      }*/
                    // if (res.data.referral.patient_id && (Number(selectedOrg.patientmodule) === 1 || Number(selectedOrg.id) === 1)) {
                    //   fetchPatient(res.data.referral.patient_id);
                    // }
                }

            } else {
                // Regular Referral endpoint
                APICall({
                    setLoading,
                    hasRefresh: true,
                    enqueueSnackbar,
                    url: `customer/${selectedOrg.id}/referral/${referalId}`,
                    verb: "get",
                    server: "api",
                }).then((res) => {
                    setLoading(false);
                    if (res) setData(res.data);
                    let data = res.data;
                    if (data) {
                        setAnalysisDetData({
                            ...analysisDetData,
                            sys_id: data.organisation.sys_id,
                            status_id: data.referral.status_id,
                            testMethod: data.referral.test_method_id,
                            referral_id: data.referral.referral_id,
                            patient_data:
                                Number(selectedOrg.id) === 0
                                    ? null
                                    : data.referral.patient_pnr
                                        ? `${data.referral.patient_name} (${data.referral.patient_pnr})`
                                        : null,
                            organisation_id: data.organisation
                                ? data.organisation.customer_number
                                : "",
                            organisation_db_id: data.organisation ? data.organisation.sys_id : "",
                            organisation_name: data.organisation ? data.organisation.name : "",
                            test_date:
                                data.referral.test_date && data.referral.test_date !== "0"
                                    ? moment.unix(data.referral.test_date).format("YYYY-MM-DD")
                                    : null,
                            created_date:
                                data.referral.registration_date &&
                                data.referral.registration_date !== "0"
                                    ? moment
                                        .unix(data.referral.registration_date)
                                        .format("YYYY-MM-DD HH:mm")
                                    : null,
                            arrival_date:
                                data.referral.arrival_date && data.referral.arrival_date !== "0"
                                    ? moment.unix(data.referral.arrival_date).format("YYYY-MM-DD HH:mm")
                                    : null,
                            result_date:
                                data.referral.result_date && data.referral.result_date !== "0"
                                    ? moment.unix(data.referral.result_date).format("YYYY-MM-DD HH:mm")
                                    : null,
                            notified_users: data.notify_users.map((item) => item.name),
                            test_case_id: data.referral.test_case_id,
                            patient_name: data.referral.patient_name,
                            patient_pnr: data.referral.patient_pnr,
                            patient_phone: data.referral.patient_phone,
                            patient_email: data.referral.patient_email,
                            is_positive: (data.referral && data.referral.is_positive) ? (data.referral.is_positive === "1" ? true : false) : false,
                            send_digital: data.referral.send_digital,
                            mvz_export_date: data.referral.mvz_export_date,
                            archived_by: data.referral.archived_by,
                            archived_at: data.referral.archived_at,
                            handled_by: data.referral.handled_by,
                            handled_at: data.referral.handled_at,
                            user_handled: data.user_handled.name,
                            user_archived: data.user_archived.name,
                            isWarranty: data.referral.warranty == 1 ? true : false,
                            referral_sys_id: data.referral.sys_id
                        })
                        if (data.organisation && data.organisation.sys_id === '2') {
                            fetchClients();
                        }

                        if (data.referral.test_case_id) {
                            setIsWorkplace(true);
                            fetchTestCase(data.referral.test_case_id)
                            fetchTestcaseTesters(data.referral.test_case_id);
                        }

                        if (data.referral.approved) {
                            setMroApproved(true);
                        }

                        /*  if (res.data.referral.patient_name) {
                            setPatient({
                              ...patient,
                              ["name"]: res.data.referral.patient_name,
                              ["id"]: res.data.referral.patient_id,
                              ["pnr"]: res.data.referral.patient_pnr
                            });
                          }*/
                        if (res.data.referral.patient_id && (Number(selectedOrg.patientmodule) === 1 || Number(selectedOrg.id) === 1)) {
                            fetchPatient(res.data.referral.patient_id);
                        }
                    }
                });
            }
        });
    };

    // eslint-disable-next-line
    const fetchStatus = () => {
        setLoading(true);
        APICall({
            setLoading,
            hasRefresh: true,
            enqueueSnackbar,
            url: `customer/${selectedOrg.id}/referral/statuses`,
            verb: "get",
            server: "api",
        }).then((res) => {
            setLoading(false);
            if (res && res.data)
                setStatus(
                    res.data.map((d) => {
                        return {id: d.sys_id, text: d.status};
                    })
                );
        });
    };

    const setApprovedMro = (referral_id, checked) => {
        APICall({
            setLoading,
            hasRefresh: true,
            enqueueSnackbar,
            url: `customer/1/referral/${referral_id}/approved_for_inspection?approved=${checked ? 1 : 0}`,
            verb: "post",
            server: "api",
        }).then((res) => {
            setLoading(false);
            if (res && res.data) {
                setApproveChecked(res.data.approved === 1 ? true : false)
                enqueueSnackbar(getTranslation('generic_submited_success'), {variant: "success"});
            }
        })
            .catch(() => setLoading(false))
    }

    const handleMroSubmit = () => {
        setLoading(true);

        if (!mroFile) {
            let form = new FormData();
            form.append("data[analysis_id]", resultsData[0].sys_id);
            form.append("data[attest]", mroApprovalValue);
            form.append("data[attest_comment]", mroText);
            APICall({
                url: `customer/1/analysis/${resultsData[0].sys_id}/attest`,
                verb: "post",
                server: "api",
                data: form
            })
                .then(response => {
                    if (response) {
                        setMroDialogOpen(false);
                        setLoading(false);
                        props.history.goBack();
                    }
                })
                .catch(() => {
                    setLoading(false);

                })
        } else {

            let fileForm = new FormData();
            fileForm.append("file", mroFile);

            APICall({
                setLoading,
                url: `customer/1/analysis/${resultsData[0].sys_id}/attest/file`,
                verb: "post",
                server: "api",
                data: fileForm
            })
                .then(res => {
                    if (res.data) {
                        let form = new FormData();
                        form.append("data[analysis_id]", resultsData[0].sys_id);
                        form.append("data[attest]", mroApprovalValue);
                        form.append("data[attest_comment]", mroText);
                        form.append("data[attest_file_id]", res.data);
                        APICall({
                            url: `customer/1/analysis/${resultsData[0].sys_id}/attest`,
                            verb: "post",
                            server: "api",
                            data: form
                        })
                            .then(response => {
                                if (response) {
                                    setMroDialogOpen(false);
                                    setLoading(false);
                                    props.history.goBack();
                                }
                            })
                            .catch(() => {
                                setLoading(false);

                            })
                    }
                })
                .catch(() => setLoading(false))
        }
    };

    // eslint-disable-next-line
    useEffect(() => {
        if (status.length === 0) {
            fetchStatus();
        } else {
            fetchData();
        }
    }, [status]);

    const openPDF = () => {
        //let url = `${process.env.REACT_APP_PROD_API}/${localStorage.getItem(process.env.REACT_APP_GUID + 'userLang') === 'sv_SE' ? 'se' : 'en'}/customer/${selectedOrg.id}/referral/${referalId}/scannedpdf`;
        let url = `${apiServer}/${localStorage.getItem(process.env.REACT_APP_GUID + 'userLang') === 'sv_SE' ? 'se' : 'en'}/customer/${selectedOrg.id}/referral/${referalId}/scannedpdf`;
        window.open(url, "_blank");
    };

    const openPDFphoto = () => {
        //let url = `${process.env.REACT_APP_PROD_API}/${localStorage.getItem(process.env.REACT_APP_GUID + 'userLang') === 'sv_SE' ? 'se' : 'en'}/customer/${selectedOrg.id}/referral/${referalId}/scannedpdf`;
        let url = `${apiServer}/${localStorage.getItem(process.env.REACT_APP_GUID + 'userLang') === 'sv_SE' ? 'se' : 'en'}/customer/${selectedOrg.id}/express_referral/${referalId}/file`;
        window.open(url, "_blank");
    };

    const handleBack = () => {
        props.history.goBack();
    };
    const fetchClients = () => {
        APICall({
            setLoading,
            enqueueSnackbar,
            url: `prodiagnostics/1/customers?filter=&sort&offset=0&limit=9999999`,
            verb: "get",
            server: "api",
        }).then((res) => {
            if (res && res.data)
                setClients(
                    res.data.filter((item) => item.sys_id !== "1" && item.sys_id !== "2")
                );
        });
    };

    // if (!clients) {
    //   fetchClients();
    // }
    const reassignReferral = () => {
        setLoading(true);

        let formData = new FormData();
        formData.append("organisation_id", customerId);
        formData.append("user_id", user.id);
        formData.append("referral[0]", data.referral.referral_id)

        APICall({
            setLoading,
            hasRefresh: true,
            enqueueSnackbar,
            url: `customer/${selectedOrg.id}/referral/${referalId}/assign`,
            verb: "post",
            server: "api",
            formData: true,
            data: formData
        }).then((res) => {
            setLoading(false);
            if (res && res.data) {
                enqueueSnackbar(getTranslation("generic_submited_success"), {
                    variant: "success",
                });
                selectedOrg && selectedOrg.id === "1"
                    ? fetchData()
                    : props.history.goBack();
            }
        });
    };

    const fetchPatient = (patientId) => {
        setLoading(true);

        APICall({
            setLoading,
            hasRefresh: true,
            enqueueSnackbar,
            url: `customer/${selectedOrg.id}/patients?limit=10&page=1&sort=pnr desc&filter[id]=${patientId}`,
        }).then((res) => {
            if (res.data.items.length > 0) {
                setPatient({name: res.data.items[0].name, pnr: res.data.items[0].pnr, id: patientId})
            }
            setLoading(false);
        });
    };

    const fetchUsersToTest = (id) => {
        let url = `customer/${selectedOrg.id}/testcase/18/users/to_test?data[limit]=1&data[offset]=0&data[test_case_id]=${id}`;

        return APICall({
            setLoading,
            enqueueSnackbar,
            url: url,
            verb: "get",
            server: "api",
            formData: false,
        });
    };

    const handleAnalysisReviewClick = () => {
        if (data.referral.test_case_id) {
            fetchUsersToTest(data.referral.test_case_id).then((res) => {
                if (res.data)
                    props.history.push(
                        `/testcase/${data.referral.test_case_id}/user/${res.data[0].sys_id}`
                    );
            });
        } else {
            props.history.push(
                `/${
                    data.referral.warranty === "0" ? "referral" : "warranty"
                }/${referalId}`
            );
        }
    };

    const handleDeleteReferralClick = () => {
        setLoading(true);
        APICall({
            setLoading,
            enqueueSnackbar,
            url: `customer/${selectedOrg.id}/referral/${referalId}/delete`,
            verb: "delete",
            server: "api",
        })
            .then((res) => {
                setDeleteModalOpen(false);
                setLoading(false);
                enqueueSnackbar(getTranslation("referral_delete_success"), {
                    variant: "success",
                });
                props.history.push("/analyses");
            })
            .catch(() => setLoading(false));
    };

    const handleArchive = () => {
        setLoading(true);

        APICall({
            setLoading,
            enqueueSnackbar,
            url: `customer/${selectedOrg.id}/referral/${referalId}/archived`,
            verb: "post",
            server: "api",
        })
            .then((res) => {
                if (res) {
                    fetchData();
                    enqueueSnackbar(getTranslation('referral_archived_success'), {variant: "success"});
                }
                setLoading(false);
            })
            .catch(setLoading(false));
    };

    const handleUnarchiveReferral = () => {
        setLoading(true);

        APICall({
            setLoading,
            enqueueSnackbar,
            url: `customer/${selectedOrg.id}/referral/${referalId}/unarchived`,
            verb: "post",
            server: "api"
        })
            .then(res => {
                if (res) {
                    fetchData();
                    enqueueSnackbar(getTranslation('referral_unarchived_success'), {variant: "success"});
                }
                setLoading(false);
            })
    };

    const handleHandleReferral = () => {
        setLoading(true);

        APICall({
            setLoading,
            enqueueSnackbar,
            url: `customer/${selectedOrg.id}/referral/${referalId}/handled`,
            verb: "post",
            server: "api"
        })
            .then(res => {
                if (res) {
                    fetchData();
                    enqueueSnackbar(getTranslation('referral_handled_success'), {variant: "success"});
                }
                setLoading(false);
            })
    };

    const handleUnhandleReferral = () => {
        setLoading(true);

        APICall({
            setLoading,
            enqueueSnackbar,
            url: `customer/${selectedOrg.id}/referral/${referalId}/unhandled`,
            verb: "post",
            server: "api"
        })
            .then(res => {
                if (res) {
                    fetchData();
                    enqueueSnackbar(getTranslation('referral_unhandled_success'), {variant: "success"});
                }
                setLoading(false);
            })
    };

    const fetchTestCase = (test_case_id) => {
        setLoading(true);

        APICall({
            url: `customer/${selectedOrg.id}/testcase/${test_case_id}`,
            verb: 'get',
            server: 'api',
        })
            .then(res => {
                if (res && res.data) {
                    setTestCaseData(res.data);

                    if (res.data.mro_id) {
                        fetchMroUsers(res.data.mro_id);
                    }
                }
                setLoading(false);
            })
            .catch(e => setLoading(false))
    }

    const fetchTestcaseTesters = (test_case_id) => {
        setLoading(true);
        APICall({
            setLoading,
            enqueueSnackbar,
            url: `customer/${selectedOrg.id}/testcase/${test_case_id}/testers`,
            verb: 'get',
            server: 'api'
        })
            .then(res => {
                if (res.data) {
                    setTestCaseTesters(res.data);
                }
                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
            })
    }

    const fetchMroUsers = (mro_id) => {
        setLoading(true);
        APICall({
            url: `customer/${selectedOrg.id}/mro?prodiagnostics=${2}`,
            verb: 'get',
            server: 'api',
        })
            .then(res => {
                if (res && res.data && res.data) {
                    let filteredUsers = res.data.filter(user => user.sys_id == mro_id);

                    if (filteredUsers.length > 0) {
                        setMroUser(filteredUsers[0]);
                    }
                }
                setLoading(false);
            })
            .catch(e => {
                console.error("Error fetching MRO users: ", e);
                setLoading(false);
            });
    }

    /*  const getUserById = userId => {
        setLoading(true);
        APICall({
          setLoading,
          enqueueSnackbar,
          url: `${props.idOrg > 1 ? 'customer' : 'prodiagnostics'}/${selectedOrg.id}/user/${props.idPersonel}`,
          verb: 'get',
          server: 'api',
        })
      };*/

    const displayDeleteButton = () => {
        if (data.referral.status_id === "1") {
            return true;
        }
        if (selectedOrg.id === "1") {
            if (data.referral.status_id === "1" || data.referral.status_id === "5" || data.referral.status_id === "6") {
                return true;
            }
        }
        return false;
    };

    const handleMroApprovalChange = e => {
        setMroApprovalValue(e.target.value);
    }

    const handleMroFileChange = e => {
        const file = e.target.files[0];
        setMroFile(file);
    }

    const handleMroMarkInProgress = () => {
        if (resultsData[0].sys_id) {
            setLoading(true);

            APICall({
                setLoading,
                enqueueSnackbar,
                url: `customer/${selectedOrg.id}/analysis/${resultsData[0].sys_id}/mro_started`,
                verb: "post",
                server: "api"
            })
                .then(res => {
                    if (res) {
                        fetchData();
                        fetchResultsData(referalId);
                        // enqueueSnackbar(getTranslation('referral_unhandled_success'), {variant: "success"});
                    }
                    setLoading(false);
                })
        }
    }

    const validMroForm = () => {
        return mroApprovalValue && mroApprovalValue !== "";
    }

    const fetchResultsData = referralId => {
        setLoading(true)
        APICall({
            setLoading,
            hasRefresh: true,
            enqueueSnackbar,
            url: `customer/${selectedOrg.id}/analysis/?sort=${sort.column} ${sort.by}&filter[referral_id]=${referralId}`,
            verb: 'get',
            server: 'api',
        }).then((res) => {
            setLoading(false);
            if (res) {
                setCount(Number(res.total_records) || 0);
                if (res.data)
                    setResultsData(res.data.map(data => {
                        setApproveChecked(data.approved === "1" ? true : false);
                        return {
                            ...data,
                            header: getTranslation('analysis_detail_table_reg_date').replace('##date##', moment.unix(data.report_date).format('YYYY-MM-DD')),
                            attest_comment: data.attest_comment || "",
                            mro_started_date: data.mro_started_date || ""
                        }
                    }))
                else
                    setResultsData([]);
            }
        })
    }

    const downloadMroFile = () => {
        let url = `${apiServer}/${localStorage.getItem(process.env.REACT_APP_GUID + 'userLang') === 'sv_SE' ? 'se' : 'en'}/customer/${selectedOrg.id}/analysis/${resultsData[0].sys_id}/mro/file/${resultsData[0].attest_file_id}`;
        window.open(url, "_blank");
    };

    return (
        <>
            <Grid container className={classes.container}>
                <Grid item xs={12} sm={10}>
                    <Paper className={classes.paperTitle} elevation={0}>
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={6}>
                                <Typography variant="h6" gutterBottom>
                                    {`${getTranslation("analysis_detail_page_title")} #${
                                        analysisDetData.referral_id || ""
                                    }`}
                                    {isWorkplace === true && ` (Workplace)`}
                                    {analysisDetData.isWarranty === true && ` (Garantiärende)`}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    {getTranslation("analysis_detail_page_subtitle")}
                                </Typography>
                            </Grid>
                            <Hidden only={["xs"]}>
                                <Grid item xs={6}>
                                    <div style={{display: "flex", justifyContent: "flex-end"}}>
                                        {(data.referral && displayDeleteButton()) && (
                                            <Button
                                                style={{
                                                    float: "right",
                                                    fontWeight: "bold",
                                                    marginRight: 10,
                                                }}
                                                variant="text"
                                                color="primary"
                                                onClick={() => setDeleteModalOpen(true)}
                                            >
                                                {getTranslation("generic_button_delete")}
                                            </Button>
                                        )}
                                        {Number(analysisDetData.status_id) === 3 && isWorkplace === false &&
                                            <Tooltip
                                                title={
                                                    analysisDetData.handled_by
                                                        ? getTranslation("analysis_handle_tooltip")
                                                            .replace(
                                                                "##date##",
                                                                moment
                                                                    .unix(analysisDetData.handled_at)
                                                                    .format("YYYY-MM-DD")
                                                            )
                                                            .replace("##username##", analysisDetData.user_handled)
                                                        : ""
                                                }
                                            >
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={
                                                        !analysisDetData.handled_by
                                                            ? handleHandleReferral
                                                            : handleUnhandleReferral
                                                    }
                                                >
                                                    {!analysisDetData.handled_by
                                                        ? getTranslation("analysis_handle_archive_btn")
                                                        : getTranslation(
                                                            "analysis_detail_unhandle_btn"
                                                        )}
                                                </Button>
                                            </Tooltip>
                                        }
                                        {isWorkplace === false && (Number(analysisDetData.status_id) === 3 || Number(analysisDetData.status_id) === 1 || Number(analysisDetData.status_id) === 5 || Number(analysisDetData.status_id) === 6) &&
                                            <Tooltip
                                                title={analysisDetData.archived_by ? getTranslation("analysis_archive_tooltip").replace('##date##', moment.unix(analysisDetData.archived_at).format("YYYY-MM-DD")).replace("##username##", analysisDetData.user_archived) : ""}
                                            >
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.archiveBtn}
                                                    onClick={!analysisDetData.archived_by ? handleArchive : handleUnarchiveReferral}
                                                >
                                                    {!analysisDetData.archived_by ? getTranslation("analysis_detail_archive_btn") : getTranslation("analysis_detail_unarchive_btn")}
                                                </Button>
                                            </Tooltip>
                                        }
                                        <Button
                                            style={{float: "right"}}
                                            variant="outlined"
                                            color="primary"
                                            onClick={handleBack}
                                            startIcon={
                                                <ArrowBackIos style={{height: 12, width: 12}}/>
                                            }
                                        >
                                            {getTranslation("generic_button_back_to_list")}
                                        </Button>
                                    </div>
                                </Grid>
                            </Hidden>
                            <Hidden smUp>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                        width: "100%",
                                        marginTop: 10,
                                    }}
                                >
                                    {Number(analysisDetData.status_id) === 3 &&
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={
                                                !analysisDetData.handled_by
                                                    ? handleHandleReferral
                                                    : handleUnhandleReferral
                                            }
                                        >
                                            {!analysisDetData.handled_by
                                                ? getTranslation("analysis_handle_archive_btn")
                                                : getTranslation("analysis_detail_unhandle_btn")}
                                        </Button>
                                    }
                                    {(Number(analysisDetData.status_id) === 3 || Number(analysisDetData.status_id) === 1 || Number(analysisDetData.status_id) === 5 || Number(analysisDetData.status_id) === 6) &&
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.archiveBtn}
                                            onClick={
                                                !analysisDetData.archived_by
                                                    ? handleArchive
                                                    : handleUnarchiveReferral
                                            }
                                        >
                                            {!analysisDetData.archived_by
                                                ? getTranslation("analysis_detail_archive_btn")
                                                : getTranslation("analysis_detail_unarchive_btn")}
                                        </Button>
                                    }
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                        width: "100%",
                                        marginTop: 20,
                                    }}
                                >
                                    {data.referral && displayDeleteButton() && (
                                        <Button
                                            style={{fontWeight: "bold"}}
                                            variant="text"
                                            color="primary"
                                            onClick={() => setDeleteModalOpen(true)}
                                        >
                                            {getTranslation("generic_button_delete")}
                                        </Button>
                                    )}
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={handleBack}
                                        startIcon={
                                            <ArrowBackIos style={{height: 12, width: 12}}/>
                                        }
                                    >
                                        {getTranslation("generic_button_back_to_list")}
                                    </Button>
                                </div>
                            </Hidden>
                        </Grid>
                    </Paper>


                    {isWorkplace && isExpressReferral && analysisDetData.is_positive &&
                        <Paper className={clsx({
                            [classes.header]: resultsData.length > 0 && (resultsData[0].attest === "1" || !resultsData[0].attest),
                            [classes.headerMro]: resultsData.length > 0 && resultsData[0].attest === "0"
                        })}>
                            <Grid container alignItems="center" justifyContent="space-between">
                                <Grid item style={{paddingLeft: 12}}>
                                    <Typography variant="h6">
                                        {(resultsData.length > 0 && resultsData[0].attest === "0") ? getTranslation("analysis_details_workplace_lab_result_negative_info_label") : getTranslation("analysis_details_workplace_lab_result_info_label")}
                                    </Typography>
                                </Grid>
                                {permLevel(selectedOrg) === 4 &&
                                    <Grid item>
                                        <FormControlLabel
                                            className={classes.forControlLabelCheckBoxApprove}
                                            control={
                                                <Checkbox
                                                    name="checked"
                                                    className={classes.checkboxMro}
                                                    checked={approveChecked}
                                                    onChange={(event) => {
                                                        setApprovedMro(analysisDetData.referral_sys_id, event.target.checked);
                                                    }}
                                                    disabled={() => {
                                                        return resultsData.length > 0 && !resultsData[0].attest_date
                                                    }}
                                                />
                                            }
                                            label={
                                                <Typography style={{color: "white"}}>{getTranslation("analysis_details_approved_mro_label")}</Typography>}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </Paper>
                    }

                    <Paper className={classes.paper}>
                        {analysisDetData.organisation_db_id === "2" && (
                            <Grid container style={{marginBottom: 30}}>
                                {/* <React.Fragment> */}
                                <Grid item xs={12}>
                                    <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                        style={{fontWeight: "bold"}}
                                    >
                                        {getTranslation("analysis_detail_reassign_title")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Autocomplete
                                        options={clients}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(event, newValue) => {
                                            setCustomerId(!newValue ? newValue : newValue.sys_id);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Button
                                        style={{margin: "0px 5px"}}
                                        variant={"contained"}
                                        color="primary"
                                        disabled={!customerId}
                                        onClick={reassignReferral}
                                        // startIcon={<SaveIcon />}
                                    >
                                        {getTranslation("generic_button_save")}
                                    </Button>
                                </Grid>
                                {/* </React.Fragment> */}
                            </Grid>
                        )}
                        <Grid container style={{marginBottom: 30}}>
                            <Grid item xs={6} sm={4}>
                                <Typography
                                    variant="subtitle1"
                                    gutterBottom
                                    style={{fontWeight: "bold"}}
                                >
                                    {getTranslation("analysis_detail_page_contact_title")}
                                </Typography>
                                <Typography variant="subtitle2" gutterBottom>
                                    {getTranslation("analysis_detail_cart_label_client")}
                                </Typography>
                                {analysisDetData.organisation_id &&
                                    <Typography
                                        variant="body2"
                                        gutterBottom
                                        style={{display: "flex", alignItems: "center"}}
                                    >
                                        {testCaseData.customer_number || analysisDetData.organisation_id}
                                        {selectedOrg &&
                                            selectedOrg.id === "1" &&
                                            testCaseData.customer_number && (
                                                <Launch
                                                    style={{
                                                        cursor: "pointer",
                                                        marginLeft: 5,
                                                        fill: "#222",
                                                    }}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        window.open(
                                                            `${window.location.origin}/organization/${testCaseData.organization_id || analysisDetData.organisation_db_id}`,
                                                            "_blank"
                                                        );
                                                    }}
                                                />
                                            )}
                                    </Typography>
                                }
                                {/*{ !analysisDetData.organisation_id &&
									<Typography
										variant="body2"
										gutterBottom
										style={{display: "flex", alignItems: "center"}}
									>
										{testCaseData.customer_number}
										{selectedOrg &&
										selectedOrg.id === "1" &&
										testCaseData.customer_number && (
											<Launch
												style={{
													cursor: "pointer",
													marginLeft: 5,
													fill: "#222",
												}}
												onClick={(e) => {
													e.preventDefault();
													window.open(
														`${window.location.origin}/organization/${testCaseData.customer_number}`,
														"_blank"
													);
												}}
											/>
										)}
									</Typography>
								}*/}
                                <Typography variant="subtitle2" gutterBottom>
                                    {getTranslation("analysis_detail_cart_label_client_name")}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    {analysisDetData.organisation_name}
                                </Typography>
                                {isWorkplace &&
                                    <>
                                        {/* Testers */}
                                        <Typography variant="subtitle2" gutterBottom>
                                            {getTranslation("analysis_detail_cart_label_testers")}
                                        </Typography>
                                        {testCaseTesters.length > 0 && testCaseTesters.map((item, ind) => (
                                            <Typography key={ind} variant="body2" gutterBottom>
                                                {item.name}
                                            </Typography>
                                        ))}
                                        {/* Testcase Title */}
                                        {Object.keys(testCaseData).length > 0 &&
                                            <>
                                                <Typography variant="subtitle2" gutterBottom>
                                                    {getTranslation("analysis_detail_cart_label_testcase_title")}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    gutterBottom
                                                    style={{display: "flex", alignItems: "center"}}
                                                >
                                                    {testCaseData.title}
                                                    {selectedOrg && selectedOrg.id === "1" && testCaseData.title && (
                                                        <Launch
                                                            style={{
                                                                cursor: "pointer",
                                                                marginLeft: 5,
                                                                fill: "#222",
                                                            }}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                window.open(
                                                                    `${window.location.origin}/testcase/${testCaseData.sys_id}`,
                                                                    "_blank"
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                </Typography>
                                            </>
                                        }
                                    </>
                                }
                                {!isWorkplace &&
                                    <Typography variant="subtitle2" gutterBottom>
                                        {getTranslation("analysis_detail_cart_label_users_notified")}
                                    </Typography>
                                }
                                {!isWorkplace && analysisDetData.notified_users.map((item, ind) => (
                                    <Typography key={ind} variant="body2" gutterBottom>
                                        {item}
                                    </Typography>
                                ))}

                                {!isWorkplace && (analysisDetData.patient_pnr && analysisDetData.patient_pnr !== "" && patient.id && (Number(selectedOrg.patientmodule) === 1) || Number(selectedOrg.id) === 1) && (
                                    <Fragment>
                                        <Typography
                                            variant="subtitle2"
                                            gutterBottom
                                            className={classes.pointer}
                                            onClick={() => setOpen(!open)}
                                        >
                                            {getTranslation(
                                                "analysis_detail_cart_label_patient_data"
                                            )}{" "}
                                            {open ? (
                                                <ExpandLess className={classes.expand}/>
                                            ) : (
                                                <ExpandMore className={classes.expand}/>
                                            )}
                                        </Typography>
                                        <Collapse in={open} timeout="auto" unmountOnExit>
                                            <div style={{display: "flex", alignItems: "center"}}>
                                                <Typography variant="body2" gutterBottom>
                                                    {`${patient.name}(${patient.pnr})`}
                                                </Typography>
                                                <Launch
                                                    style={{
                                                        cursor: "pointer",
                                                        marginLeft: 5,
                                                        fill: "#222",
                                                        marginBottom: 6,
                                                    }}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        window.open(
                                                            `${window.location.origin}/patients/${patient.id}`,
                                                            "_blank"
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </Collapse>
                                    </Fragment>
                                )}

                                {isWorkplace &&
                                    <React.Fragment>
                                        <Typography variant="subtitle2" gutterBottom>
                                            {getTranslation("analysis_detail_cart_label_mro_user")}
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            {mroUser.name}
                                        </Typography>
                                    </React.Fragment>
                                }

                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <Typography
                                    variant="subtitle1"
                                    gutterBottom
                                    style={{fontWeight: "bold"}}
                                >
                                    {getTranslation("analysis_detail_page_status_title")}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    gutterBottom
                                    style={{color: "#28A745"}}
                                >
                                    <Check style={{fill: "#28A745"}}/>
                                    {analysisDetData.created_date
                                        ? getTranslation(
                                            "analysis_detail_page_referral_sent"
                                        ).replace("##date##", analysisDetData.created_date)
                                        : getTranslation(
                                            "analysis_detail_page_referral_sent"
                                        ).replace(", ##date##", "")}
                                </Typography>
                                {!isWorkplace &&
                                    <SendDigitalInfo
                                        sendDigital={analysisDetData.send_digital}
                                        mvzExportDate={analysisDetData.mvz_export_date}
                                    />
                                }
                                {analysisDetData.arrival_date &&
                                    <Typography
                                        variant="body2"
                                        gutterBottom
                                        style={{
                                            color: analysisDetData.arrival_date ? "#28A745" : "#B7B7B7",
                                        }}
                                    >
                                        <Check
                                            style={{
                                                fill: analysisDetData.arrival_date
                                                    ? "#28A745"
                                                    : "#B7B7B7",
                                            }}
                                        />
                                        {analysisDetData.arrival_date
                                            ? getTranslation(
                                                "analysis_detail_page_referral_received_date"
                                            ).replace("##date##", analysisDetData.arrival_date)
                                            : getTranslation(
                                                "analysis_detail_page_referral_received_no_date"
                                            )}
                                    </Typography>
                                }
                                <Typography
                                    variant="body2"
                                    gutterBottom
                                    style={{
                                        color: analysisDetData.result_date ? "#28A745" : "#B7B7B7",
                                    }}
                                >
                                    <Check
                                        style={{
                                            fill: analysisDetData.result_date ? "#28A745" : "#B7B7B7",
                                        }}
                                    />
                                    {analysisDetData.result_date
                                        ? getTranslation(
                                            "analysis_detail_page_referral_reply_date"
                                        ).replace("##date##", analysisDetData.result_date)
                                        : getTranslation(
                                            "analysis_detail_page_referral_reply_no_date"
                                        )}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                {data &&
                                    data.referral &&
                                    !data.referral.auto_generated &&
                                    analysisDetData.test_date && (
                                        <Button
                                            className={classes.cartButton}
                                            variant="contained"
                                            color="primary"
                                            startIcon={<Assignment/>}
                                            onClick={() => handleAnalysisReviewClick()}
                                        >
                                            {getTranslation("analysis_detail_cart_button_review")}
                                        </Button>
                                    )}
                                {/*MRO section*/}
                                {((selectedOrg.mro || permLevel(selectedOrg) === 4) && isExpressReferral && expressReferralHasData) &&
                                    <>
                                        <Grid container>
                                            {((!resultsData[0]?.attest_date || selectedOrg.mro) && analysisDetData.is_positive) &&
                                                <Grid item xs={12}>
                                                    <Button
                                                        className={classes.cartButton}
                                                        variant="contained"
                                                        style={{
                                                            color: "white",
                                                            backgroundColor: resultsData[0]?.mro_started_date ? "#009933" : "#E41937"
                                                        }}
                                                        onClick={() => handleMroMarkInProgress()}
                                                        disabled={resultsData[0]?.mro_started_date}
                                                    >
                                                        {resultsData[0]?.mro_started_date ? getTranslation("analysis_detail_mro_mark_in_progress").replace("##date##", moment.unix(resultsData[0]?.mro_started_date).format('YYYY-MM-DD HH:mm')) : getTranslation("analysis_detail_mro_mark_progress")}
                                                    </Button>
                                                </Grid>
                                            }
                                            {(resultsData[0]?.attest_date || selectedOrg.mro)&&
                                                <Grid item xs={12}>
                                                    <TextField
                                                        variant="filled"
                                                        label={getTranslation('analysis_detail_mro_text_label')}
                                                        name='mroText'
                                                        className={classes.mroTextInput}
                                                        multiline
                                                        maxRows="4"
                                                        value={(!selectedOrg.mro && resultsData.length > 0) ? resultsData[0].attest_comment : mroText}
                                                        disabled={!selectedOrg.mro}
                                                        onChange={e => setMroText(e.target.value)}
                                                    />
                                                </Grid>
                                            }
                                            <Grid item xs={12}>
                                                {selectedOrg.mro &&
                                                    <>
                                                        <input
                                                            accept=".pdf"
                                                            className={classes.mroUploadFileInput}
                                                            id="upload-file-mro"
                                                            type="file"
                                                            onChange={handleMroFileChange}
                                                        />
                                                        <label htmlFor="upload-file-mro">
                                                            <Button variant="outlined" color="primary"
                                                                    component="span">
                                                                {getTranslation("invoices_pdf_uploader_page_title")}
                                                            </Button>
                                                        </label>
                                                    </>
                                                }
                                                {(permLevel(selectedOrg) === 4 && resultsData.length > 0 && resultsData[0]?.attest_date) &&
                                                    <>
                                                        {resultsData[0].attest_file_id &&
                                                            <Button variant="outlined" color="primary"
                                                                    component="span"
                                                                    onClick={downloadMroFile}>
                                                                {getTranslation("analysis_detail_mro_form_download_file")}
                                                            </Button>
                                                        }
                                                    </>
                                                }
                                            </Grid>
                                            {(resultsData[0]?.attest_date || selectedOrg.mro) &&
                                                <Grid item xs={12}>
                                                    <form className={classes.mroApproveRadioForm}>
                                                        <FormControl className={classes.mroApprovalFormControl}>
                                                            <RadioGroup aria-label="mroapproval" name="mroapproval"
                                                                        value={(!selectedOrg.mro && resultsData.length > 0) ? resultsData[0].attest : mroApprovalValue}
                                                                        onChange={handleMroApprovalChange}
                                                                        style={{paddingBottom: 0}}>
                                                                <FormControlLabel
                                                                    value="1"
                                                                    control={<Radio/>}
                                                                    label={getTranslation("analysis_detail_mro_approve_checkbox")}
                                                                    disabled={!selectedOrg.mro}/>
                                                                <FormControlLabel
                                                                    value="0"
                                                                    control={<Radio/>}
                                                                    label={getTranslation("analysis_detail_mro_deny_deny")}
                                                                    disabled={!selectedOrg.mro}/>
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </form>
                                                    {(mroApprovalValue === "" && selectedOrg.mro) &&
                                                        <span
                                                            className={classes.mroRadioHelperText}>{getTranslation("generic_error_required_field")}
                                                    </span>
                                                    }
                                                </Grid>
                                            }
                                            {selectedOrg.mro &&
                                                <Grid xs={12} style={{marginTop: 15}}>
                                                    <Button
                                                        className={classes.cartButton}
                                                        variant="outlined"
                                                        color="primary"
                                                        disabled={!validMroForm()}
                                                        onClick={() => setMroDialogOpen(true)}
                                                    >
                                                        {getTranslation(
                                                            "analysis_detail_mro_confirm_button"
                                                        )}
                                                    </Button>
                                                </Grid>
                                            }
                                        </Grid>
                                        {((resultsData.length > 0 && !resultsData[0].attest_date && resultsData[0].approved === "1") && permLevel(selectedOrg) === 4 && analysisDetData.is_positive) &&
                                            <Grid item xs={12}>
                                                <Typography variant="body2">
                                                    {getTranslation("analysis_details_waiting_mro_response")}
                                                </Typography>
                                            </Grid>
                                        }
                                    </>
                                }
                                {data && data.referral && data.referral.referral_scan_file_id && (
                                    <Button
                                        className={classes.cartButton}
                                        startIcon={<PictureAsPdf/>}
                                        color="primary"
                                        onClick={() => {
                                            openPDF();
                                        }}
                                    >
                                        {getTranslation(
                                            "analysis_detail_cart_button_request_at_lab"
                                        )}
                                    </Button>
                                )}
                                {isWorkplace && isExpressReferral && (
                                    <Button
                                        className={classes.cartButton}
                                        style={{marginTop: 20}}
                                        startIcon={<PictureAsPdf/>}
                                        color="primary"
                                        onClick={() => {
                                            openPDFphoto();
                                        }}
                                    >
                                        {getTranslation(
                                            "analysis_detail_cart_button_picture_button_pdf"
                                        )}
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                        {(!isExpressReferral || (isExpressReferral && expressReferralHasData)) && (
                            <SimpleTable
                                {...props}
                                testMethod={analysisDetData.testMethod}
                                id={analysisDetData.referral_sys_id ? analysisDetData.referral_sys_id : analysisDetData.referral_id}
                                data={resultsData}
                                count={count}
                                sort={sort}
                                fetchData={fetchResultsData}
                            />
                        )}
                        <div style={{display: "flex", justifyContent: "flex-end"}}>
                            <Button
                                style={{margin: "40px 0px 20px 0px"}}
                                variant="outlined"
                                color="primary"
                                onClick={handleBack}
                                startIcon={<ArrowBackIos style={{height: 12, width: 12}}/>}
                            >
                                {getTranslation("generic_button_back_to_list")}
                            </Button>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
            <Dialog open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
                <DialogTitle>
                    {getTranslation("delete_referral_modal_title")}
                </DialogTitle>
                <DialogContent>
                    {getTranslation("delete_referral_modal_body")}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleDeleteReferralClick}
                        color="primary"
                        variant="contained"
                    >
                        {getTranslation("generic_button_delete")}
                    </Button>
                    <Button
                        onClick={() => setDeleteModalOpen(false)}
                        color="primary"
                        variant="contained"
                    >
                        {getTranslation("generic_button_cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
            {/*MRO Dialog*/}
            <Dialog open={mroDialogOpen}>
                <DialogTitle>
                    {getTranslation("mro_dialog_modal_title")}
                </DialogTitle>
                <DialogContent>
                    {getTranslation("mro_dialog_modal_body")}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleMroSubmit}
                        color="primary"
                        variant="contained"
                    >
                        {getTranslation("generic_button_save")}
                    </Button>
                    <Button
                        onClick={() => setMroDialogOpen(false)}
                        color="primary"
                        variant="contained"
                    >
                        {getTranslation("generic_button_cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
};
export default AnalysesDetail;
