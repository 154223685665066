import React, {useRef} from "react";
import {Camera} from "react-camera-pro";
import {makeStyles} from "@material-ui/core/styles";
import colorConfig from "../../styles/Theme-settings";
import CameraIcon from "@material-ui/icons/Camera";

const CameraComp = props => {
    const classes = useStyles();

    const cameraRef = useRef(null);

    const {handleOnClick, onClose} = props;

    return (
        <div className={classes.cameraWrap}>
            <Camera ref={cameraRef} aspectRatio={16 / 9} facingMode='environment'/>
            <div className={classes.captureBtnWrap}>
                <CameraIcon
                    className={classes.captureBtn}
                    onClick={() => {
                        if (cameraRef.current) {
                            const photo = cameraRef.current.takePhoto();
                            handleOnClick(photo);
                            onClose();
                        }
                    }}/>
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
        cameraWrap: {
            //zIndex: 1101,
            //position: "absolute",
            width: "100vw",
            //height: "100vh",
            //left: 0,
            //top: 0
        },
        captureBtnWrap: {
            zIndex: 100,
            position: "relative",
            //width: "100vw",
            //height: "100vh"
        },
        captureBtn: {
            fill: "rgba(255, 255, 255, 0.8)",
            position: "absolute",
            height: 50,
            width: 50,
            transform: "translate(-50%, 0%)",
            left: "50%",
            bottom: 15,
            cursor: "pointer"
        }
    }))
;

export default CameraComp;