import React, {useEffect} from "react";
import Quagga from "quagga";
import "./style.css";

const BarcodeScanner = (props) => {
    const scannerContainer = document.getElementById('interactive');

    useEffect(() => {
        Quagga.init(
            {
                inputStream: {
                    type: "LiveStream",
                    target: scannerContainer,
                    constraints: {
                        width: window.innerWidth,
                        height: window.innerHeight,
                        facingMode: "environment",
                    },
                },
                locator: {
                    patchSize: "medium",
                    halfSample: true,
                },
                numOfWorkers: 2,
                decoder: {
                    readers: ["i2of5_reader",]
                },
                locate: true,
            },
            function (err) {
                if (err) {
                    return console.log(err);
                }
                Quagga.start();
            }
        );
        Quagga.onDetected(onDetected);

        return () => {
            Quagga.offDetected(onDetected);
            Quagga.stop();
        }
    }, []);

    const onDetected = (result) => {
        if (result.codeResult.code.length === 10) {
            props.checkBarcodeInUse(result.codeResult.code);
            const key = props.isWorkplace ? "barcode" : "id";
            props.setData({...props.data, [key]: result.codeResult.code});
            props.setIsScanning(false);
            Quagga.stop();
        }
    }

    return (
        <div id="interactive" className="viewport"
             style={{zIndex: 1101, position: "fixed", top: 0, left: 0, width: "100%", height: "100%"}}/>
    )
};

export default BarcodeScanner;