import React, {useContext, useEffect} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
  // Link
} from "react-router-dom";
import {permLevel} from '../functional/etc';
import {AuthContext} from '../context/authContext';
import {OrgContext} from '../context/orgContext';
import {LocalizationContext} from '../context/localizationContext';
import "../styles/cssFixes.css";

// import Layouts
import Auth from "../layout/auth";
import DashboardUI from "../layout/dashboard";

// Import Pages
import LoginAlt from "../pages/loginAlt";
import Recover from "../pages/Recover-Password/Recover";
import Reset from "../pages/Recover-Password/Reset";
import OrganizationSelector from "../pages/organization/organization-selector";
import OrganizationInfo from "../pages/organization/organization-info";
import OrganizationPersonel from "../pages/organization/organization-personel";
import Dashboard from "../pages/dashboard";
import Analyses from "../pages/analyses/analyses";
import AnalysesDetail from "../pages/analyses/analysesDetail";
import Referal from '../pages/referal/index';
import TranslationsList from '../pages/translations/translationsList';
import TranslationsEdit from '../pages/translations/translationsEdit';
import Workplace from "../pages/workplace";
import CreateTestOccasion from "../pages/workplace/create";
import DuplicatedTestOccasion from "../pages/workplace/duplicated";
import ListTestOccasions from "../pages/workplace/list";
import TestCaseDetails from "../pages/workplace/details-lite";
import TestCasePersonalDetails from "../pages/workplace/personal-details";
import Educational from '../pages/educational';
import Invoices from '../pages/invoices/invoices';
import OrganizationsList from '../pages/organization/organizations-list';
import InvoicesDetail from '../pages/invoices/invoicesDetail';
import InvoicesMvzImport from '../pages/invoices/importMvzFile';
import InvoicesMvzExport from '../pages/invoices/exportMvzFile';
import InvoicesPDFUploader from '../pages/invoices/invoicesPDFUploader';
import NotificationInbox from '../pages/NotificationInbox';
import Loading from '../components/Loading';
import ErrorPage from '../pages/ErrorPage';
import ErrorBoundary from '../functional/ErrorBoundary'
import NotFound from '../pages/404';
import AddUser from "../pages/workplace/add-user";
import ReferralsSlider from "../components/Slider/ReferralsSlider";
import PatientsList from "../pages/patients/list";
import PatientDetails from "../pages/patients/patient-details";
import AuthBankIdHosted from "../pages/AuthBankIdHosted";
import ActivitiesLogsList from "../pages/activitylogs/activitiesLogsList";

const RouterLayout = ({component: Component, layout: Layout, ...props}) => {
  if (!Layout) Layout = Auth;
  return (
    <Route
      {...props}
      render={routeProps => (
        <ErrorBoundary {...routeProps}>
          <Layout {...routeProps}>
            <Component {...routeProps} />
          </Layout>
        </ErrorBoundary>
      )}
    />
  );
};

const RouterSwitch = (props) => {
  const {user, perm, authType, tempCustNr} = useContext(AuthContext);
  const {selectedOrg, rehydrate} = useContext(OrgContext);
  const {localizeList, getTranslation} = useContext(LocalizationContext);

  document.title = getTranslation('generic_browser_title') + ' | Prodiagnostics';

  const currentURL = window.location.href;

  let authed = ((user && user.user_id) && (authType && authType.complete));

  const getPerm = () => {
    return permLevel(selectedOrg);
  }
  let selOrg = selectedOrg || localStorage.getItem(process.env.REACT_APP_GUID + 'so')
  const showReferralSlider = () => {
    return (!(currentURL.includes("/workplace") || currentURL.includes("/testcase"))) && !selectedOrg.mro && !selectedOrg.consult;
  };

  useEffect(() => {
    if (tempCustNr) {
      rehydrate()
    }
    // eslint-disable-next-line
  }, [tempCustNr])

  useEffect(() => {
    let iframes = document.getElementsByTagName("iframe");
    if (iframes.length > 0) {
      iframes[0].classList.add("hideIframe");
    }
  }, []);

  if (!localizeList) {
    return (<Loading/>)
  } else {
    return (
      <>
        {(authed && selOrg && showReferralSlider()) && <ReferralsSlider/>}
        <Router>
          {!authed &&
          <Switch>
            <RouterLayout layout={Auth} exact path="/error-page" component={ErrorPage}/>
            <RouterLayout layout={Auth} exact path="/" component={LoginAlt}/>
            <RouterLayout layout={Auth} exact path="/login" component={LoginAlt}/>
            {/* <RouterLayout layout={Auth} exact path="/recover/:lang" component={Reset} /> */}
            <RouterLayout layout={Auth} exact path="/reset/:lang/:token" component={Reset}/>
            <RouterLayout layout={Auth} exact path="/recover/:lang" component={Recover}/>
            <RouterLayout layout={Auth} exact path="/get-bank-id-session" component={AuthBankIdHosted}/>
              <RouterLayout layout={Auth} component={LoginAlt}/>
          </Switch>
          }
          {authed && !selOrg &&
          <Switch>
            <RouterLayout layout={DashboardUI} exact path="/error-page" component={ErrorPage}/>
            <Route exact path="/login">
              <Redirect to="/"/>
            </Route>
            <RouterLayout layout={Auth} exact path="/get-bank-id-session" component={AuthBankIdHosted}/>
            <RouterLayout layout={DashboardUI} component={OrganizationSelector}/>
          </Switch>
          }
          {authed && selOrg && selOrg.mro &&
          <Switch>
            <RouterLayout layout={DashboardUI} exact path="/error-page" component={ErrorPage}/>
            <RouterLayout layout={DashboardUI} exact path="/" component={Analyses}/>
            <RouterLayout layout={DashboardUI} exact path="/analyses" component={Analyses}/>
            <RouterLayout layout={DashboardUI} exact path="/organization/settings" component={OrganizationInfo}/>
            <RouterLayout layout={DashboardUI} exact path="/analyses/detail/:id" component={AnalysesDetail}/>
            <RouterLayout layout={DashboardUI} exact path="/testcase/:id" component={CreateTestOccasion}/>
            {/*<RouterLayout layout={DashboardUI} exact path="/testcase/duplicated" component={DuplicatedTestOccasion}/>*/}
            <RouterLayout layout={Auth} exact path="/get-bank-id-session" component={AuthBankIdHosted}/>
          </Switch>
          }
          {authed && selOrg && !selOrg.mro &&
          <Switch>
            <RouterLayout layout={DashboardUI} exact path="/error-page" component={ErrorPage}/>
            <RouterLayout layout={DashboardUI} exact path="/" component={Dashboard}/>
            <RouterLayout layout={DashboardUI} exact path="/dashboard" component={Dashboard}/>
            {getPerm() > 2 &&
            <RouterLayout layout={DashboardUI} exact path="/organizations" component={OrganizationsList}/>
            }
            {perm && perm.options.length > 1 &&
            <RouterLayout layout={DashboardUI} exact path="/organization/selector" component={OrganizationSelector}/>
            }
            <RouterLayout layout={DashboardUI} exact path="/organization/:id" component={OrganizationInfo}/>
            <RouterLayout layout={DashboardUI} exact path="/organization/:id/personel/:idPersonel"
                          component={OrganizationPersonel}/>
            {getPerm() > 2 &&
            <RouterLayout layout={DashboardUI} exact path="/translations" component={TranslationsList}/>
            }
            {getPerm() > 2 &&
            <RouterLayout layout={DashboardUI} exact path="/logs-listing" component={ActivitiesLogsList}/>
            }
            {getPerm() > 2 &&
            <RouterLayout layout={DashboardUI} exact path="/translations/:id" component={TranslationsEdit}/>
            }
            <RouterLayout layout={DashboardUI} exact path="/analyses" component={Analyses}/>
            <RouterLayout layout={DashboardUI} exact path="/analyses/detail/:id" component={AnalysesDetail}/>
            {getPerm() <= 2 &&
            <RouterLayout layout={DashboardUI} exact path="/warranty/filled" component={Referal}/>
            }
            {getPerm() <= 2 &&
            <RouterLayout layout={DashboardUI} exact path="/warranty" component={Referal}/>
            }
            {getPerm() <= 2 &&
            <RouterLayout layout={DashboardUI} exact path="/referral/filled" component={Referal}/>
            }
            {getPerm() <= 2 &&
            <RouterLayout layout={DashboardUI} exact path="/referral" component={Referal}/>
            }
            {((selectedOrg.workplace === "1" && getPerm() <= 2) || getPerm() >= 3) &&
            <RouterLayout layout={DashboardUI} exact path="/workplace" component={Workplace}/>
            }
            {((selectedOrg.workplace === "1" && getPerm() <= 2) || getPerm() >= 3) &&
            <RouterLayout layout={DashboardUI} exact path="/testcase" component={CreateTestOccasion}/>
            }
            {((selectedOrg.workplace === "1" && getPerm() <= 2) || getPerm() >= 3) &&
            <RouterLayout layout={DashboardUI} exact path="/testcase/duplicated" component={DuplicatedTestOccasion}/>
            }
            {((selectedOrg.workplace === "1" && getPerm() <= 2) || getPerm() >= 3) &&
            <RouterLayout layout={DashboardUI} exact path="/testcase/list" component={ListTestOccasions}/>
            }
            {((selectedOrg.workplace === "1" && getPerm() <= 2) || getPerm() >= 3) &&
            <RouterLayout layout={DashboardUI} exact path="/testcase/:id" component={CreateTestOccasion}/>
            }
            {((selectedOrg.workplace === "1" && getPerm() <= 2) || getPerm() >= 3) &&
            <RouterLayout layout={DashboardUI} exact path="/testcase/details/:id" component={TestCaseDetails}/>
            }
            {((selectedOrg.workplace === "1" && getPerm() <= 2) || getPerm() >= 3) &&
            <RouterLayout layout={DashboardUI} exact path="/testcase/:id/user/add" component={AddUser}/>
            }
            {((selectedOrg.workplace === "1" && getPerm() <= 2) || getPerm() >= 3) &&
            <RouterLayout layout={DashboardUI} exact path="/testcase/:id/user/:userId"
                          component={TestCasePersonalDetails}/>
            }
            <RouterLayout layout={DashboardUI} exact path="/referral/:id" component={Referal}/>
            <RouterLayout layout={DashboardUI} exact path="/warranty/:id" component={Referal}/>

            {(Number(selectedOrg.patientmodule) === 1 || Number(selectedOrg.id) === 1) &&
            <RouterLayout layout={DashboardUI} exact path="/patients" component={PatientsList}/>
            }
            {(Number(selectedOrg.patientmodule) === 1 || Number(selectedOrg.id) === 1) &&
            <RouterLayout layout={DashboardUI} exact path="/patients/:id" component={PatientDetails}/>
            }

            <RouterLayout layout={DashboardUI} exact path="/invoices" component={Invoices}/>
            <RouterLayout layout={DashboardUI} exact path="/invoices/importmvz" component={InvoicesMvzImport}/>
            <RouterLayout layout={DashboardUI} exact path="/invoices/exportmvz" component={InvoicesMvzExport}/>
            <RouterLayout layout={DashboardUI} exact path="/invoices/pdf-uploader" component={InvoicesPDFUploader}/>
            <RouterLayout layout={DashboardUI} exact path="/invoices/:id" component={InvoicesDetail}/>
            <RouterLayout layout={DashboardUI} exact path="/educational" component={Educational}/>
            {/* <RouterLayout layout={DashboardUI} exact path="/profile" component={Profile} /> */}
            <RouterLayout layout={DashboardUI} exact path="/notifications" component={NotificationInbox}/>
            <RouterLayout layout={Auth} exact path="/get-bank-id-session" component={AuthBankIdHosted}/>
            <Route exact path="/login">
              <Redirect to="/"/>
            </Route>
            <RouterLayout layout={DashboardUI} component={NotFound}/>
          </Switch>
          }
        </Router>
      </>
    );
  }
};

export default RouterSwitch;
